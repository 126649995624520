import { createRouter as _createRouter, createMemoryHistory, createWebHistory } from 'vue-router'
const baseUrl = import.meta.env.BASE_URL;
const history = import.meta.env.SSR ? createMemoryHistory(baseUrl) : createWebHistory(baseUrl);

function createDynamicRoute(name) {
    const re = /\@[a-z]*/g;
    var res = name.replaceAll('!', ':');;
    if (name.match(re)) {
        res = name.match(re).reduce((acc, current) => acc.replace(current, createRegex(current) + '?'), res).replaceAll('@', ':');;
    }
    return res;
}

function cleanName(name) {
    const re = /\@[a-z]*\//g;
    var res = name.replaceAll('!', '@')
    if (name.match(re)) {
        res = res.match(re).reduce((acc, current) => acc.replace(current, ''), name).replaceAll('/index', '');
    }
    return res;
}

var pages = import.meta.glob('../pages/**/*.vue')

const routes = Object.keys(pages).map((path) => {
    var route = {};
    const name = path.match(/\.\/pages(.*)\.vue$/)[1].toLowerCase();
    var url = createDynamicRoute(name);
    if (url.includes('/index')) {
        url = url.replace('/index', '');
    }
    route.path = url;
    route.component = pages[path];
    route.name = cleanName(name);

    return route
})

routes.push({ path: '/:pathMatch(.*)*', name: '/404', meta: { error: true, errorCode: 404}, component: () => import('@/__aurora/errors/404.vue') })


// export const nonDynamicRoutes = routes.filter(route => !route.path.match(/.+\/\:/));
// export const nestedRoutes = nonDynamicRoutes.filter(route => route.name.match(/\/[a-z]*\/.+/));
// export const nonNestedRoutes = nonDynamicRoutes.filter(route => !route.name.match(/\/[a-z]*\/.+/));
// export const baseRoutes = nonNestedRoutes.filter(route => !nestedRoutes.some(nested => nested.name.includes(route.name)));

// console.log(routes)

export function createRouter() {
    return _createRouter({
        history,
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                }
            } else {
                return { 
                    top: 0,
                    behavior: 'instant'
                }
            }
        },
    })
}