import { createSSRApp } from 'vue';
import App from '@/app.vue';
import { createRouter } from './router';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';

import lazyload from 'vue3-lazyload'; 

export function createApp() {
    const app = createSSRApp(App)
    // app.config.globalProperties.$http = () => {}

    const router = createRouter()
    app.use(router)
    
    const pinia = createPinia()
    app.use(pinia)

    const head = createHead()
    app.use(head)    
   
    app.use(lazyload, {})
    
    return { app, head, router }
}
