import './styles/normalize.scss'
import './styles/core.scss'
import './styles/aurora.scss'
import '../styles/_animations.scss'
import '../styles/_inputs.scss'

import { createApp } from './main'

const { app, router } = createApp()

router.isReady().then(() => {
    app.mount('#app')
})
